<template>
  <div class="container">
    <div class="card overflow-y overlapping-container has-top-shadow">
      <div class="card-content pb-6 px-4 pt-1">
        <CardNavigation/>
        <ViewsStat :pageDetails="{pageName: 'faciMessaging'}"/>
        <div class="container has-text-centered mb-1">
          <div class="pt-4 is-size-5 has-text-weight-semibold has-baskerville-font has-text-brand-color">
            Message the Facilitators
          </div>
        </div>
        <div class="container">
          <div class="is-size-6 has-text-grey mb-5 px-4 has-text-centered">
            Contact your facilitators here! Your facilitators will reply to you via Chat at the bottom right hand corner.
          </div>
          <FormInput
          :size="'medium'" :title="'Send something...'" :type="'text'" :value="text" v-on:update="text = $event"
          />
          <div v-if="showSuccessMessage" class="is-size-6 has-text-grey has-text-centered mt-2 px-4">
            Sent! Your facilitators will reply to you via Chat at the bottom right hand corner.
          </div>
          <div v-if="!showSuccessMessage" class="level-item has-text-centered mb-2">
            <div @click="!text || loading ? null : addChat()"
            class="button is-primary-colors has-text-weight-semibold is-fullwidth mt-0"
            v-bind:class="{'is-light is-loading': loading}"
            :disabled="!text"
            >
              Send
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardNavigation from '@/components/CardNavigation'
import firebaseApp from '@/firebase/init'
import FormInput from '@/components/forms/FormInput'
import ViewsStat from '@/components/ViewsStat.vue'

export default {
  name: 'FaciMessaging',
  components: {
    FormInput,
    ViewsStat,
    CardNavigation
  },
  data(){
    return {
      showSuccessMessage: false,
      loading: false,
      submittedText: false,
      text: ''
    }
  },
  computed: {
    adventureTeam() {
      return this.$store.state.adventureTeam
    }
  },
  methods: {
    addChat(){
      if(!this.loading && this.text){
        this.loading = true
        const masterFunction = firebaseApp.functions('asia-northeast1').httpsCallable('masterFunction')
        masterFunction({
          methodName: 'message-faci',
          teamCode: this.$store.state.teamCode,
          userName: this.$store.state.userName,
          text: this.text
        }).then(res => {
          this.showSuccessMessage = true
          setTimeout(() => { this.showSuccessMessage = false }, 3000)
          this.text = ''
          this.submittedText = true
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch('enterPage', {
      pageName: 'faciMessaging'
    })
  }
}
</script>

<style>
@keyframes chat-box-animation-name {
  0%   { background-color: #FED892; }
  100% { background-color: #ffffff; }
}

.chat-box-animation {
  animation: chat-box-animation-name 1s ease-in forwards;
}
</style>
